import { createBrowserRouter, redirect } from "react-router-dom";
import { LazyErrorPage } from "library-ui-components/pages";

export const router = createBrowserRouter([
  {
    path: "/",
    loader: () => redirect("/duty-handover"),
  },
  {
    path: "/duty-handover",
    async lazy() {
      const c = await import("./Layout.tsx");
      return { Component: c.default };
    },
    errorElement: <LazyErrorPage />,
    children: [
      {
        id: "home",
        path: "",
        async lazy() {
          const c = await import("../pages/Home/HomePage.tsx");
          return { Component: c.default };
        },
      },
      {
        id: "scheduler",
        path: "scheduler",
        async lazy() {
          const c = await import("../pages/Scheduler/SchedulerPage.tsx");
          return { Component: c.default };
        },
      },
    ],
  },
  {
    path: "*",
    element: <LazyErrorPage />,
  },
]);
