import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router";
import { ThemeWrapper } from "library-ui-components";
import { BrowserAuthWrapper } from "library-frontend-utils/auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import config from "../deploy";
import { LoadingPage } from "library-ui-components/pages";

const queryClient = new QueryClient();

function App() {
  return (
    <BrowserAuthWrapper config={config}>
      <QueryClientProvider client={queryClient}>
        <ThemeWrapper>
          <RouterProvider router={router} fallbackElement={<LoadingPage />} />
        </ThemeWrapper>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserAuthWrapper>
  );
}

export default App;
